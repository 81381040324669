export const SIDEBAR_SHOW = 'SIDEBAR_SHOW'

// Auth
export const INFO_USER = 'INFO_USER'
export const DATA_SIDE_BAR = 'DATA_SIDE_BAR'

// Website
export const DASHBOARD_TECH = 'DASHBOARD_TECH'
export const ORDER_TECH = 'ORDER_TECH'
export const DASHBOARD_SAAS = 'DASHBOARD_SAAS'
export const ORDER_SAAS = 'ORDER_SAAS'

// Product Brand
export const GET_PRODUCT_BRAND = 'GET_PRODUCT_BRAND'
export const POST_PRODUCT_BRAND = 'POST_PRODUCT_BRAND'
export const PUT_PRODUCT_BRAND = 'PUT_PRODUCT_BRAND'
export const DELETE_PRODUCT_BRAND = 'DELETE_PRODUCT_BRAND'

// Product Categories
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES'
export const POST_PRODUCT_CATEGORIES = 'POST_PRODUCT_CATEGORIES'
export const PUT_PRODUCT_CATEGORIES = 'PUT_PRODUCT_CATEGORIES'
export const DELETE_PRODUCT_CATEGORIES = 'DELETE_PRODUCT_CATEGORIES'

// Product Attributes
export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES'
export const POST_PRODUCT_ATTRIBUTES = 'POST_PRODUCT_ATTRIBUTES'
export const PUT_PRODUCT_ATTRIBUTES = 'PUT_PRODUCT_ATTRIBUTES'
export const DELETE_PRODUCT_ATTRIBUTES = 'DELETE_PRODUCT_ATTRIBUTES'

// Attributes Values
export const GET_ATTRIBUTES_VALUE = 'GET_ATTRIBUTES_VALUE'
export const POST_ATTRIBUTES_VALUE = 'POST_ATTRIBUTES_VALUE'
export const PUT_ATTRIBUTES_VALUE = 'PUT_ATTRIBUTES_VALUE'
export const DELETE_ATTRIBUTES_VALUE = 'DELETE_ATTRIBUTES_VALUE'

// Product
export const GET_PRODUCT = 'GET_PRODUCT'
export const POST_PRODUCT = 'POST_PRODUCT'
export const PUT_PRODUCT = 'PUT_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'

// Import
export const IMPORT_PRODUCT = 'IMPORT_PRODUCT'
export const IMPORT_CATEGORIES = 'IMPORT_CATEGORIES'
export const IMPORT_BRAND = 'IMPORT_BRAND'
export const IMPORT_ACTUAL_PHOTOS = 'IMPORT_ACTUAL_PHOTOS'
export const EXPORT_ACTUAL_PHOTOS = 'EXPORT_ACTUAL_PHOTOS'

// System Account
export const GET_ACCOUNT = 'GET_ACCOUNT'
export const POST_ACCOUNT = 'POST_ACCOUNT'
export const PUT_ACCOUNT = 'PUT_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const GET_ROLES = 'GET_ROLES'

// Account Service
export const GET_ACCOUNT_APP = 'GET_ACCOUNT_APP'
export const POST_ACCOUNT_APP = 'POST_ACCOUNT_APP'
export const PUT_ACCOUNT_APP = 'PUT_ACCOUNT_APP'
export const DELETE_ACCOUNT_APP = 'DELETE_ACCOUNT_APP'

export const GET_ACCOUNT_COMPANY = 'GET_ACCOUNT_COMPANY'
export const POST_ACCOUNT_COMPANY = 'POST_ACCOUNT_COMPANY'
export const PUT_ACCOUNT_COMPANY = 'PUT_ACCOUNT_COMPANY'
export const DELETE_ACCOUNT_COMPANY = 'DELETE_ACCOUNT_COMPANY'

export const GET_COMPANY_USER = 'GET_COMPANY_USER'
export const POST_COMPANY_USER = 'POST_COMPANY_USER'
export const PUT_COMPANY_USER = 'PUT_COMPANY_USER'
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER'

export const GET_SALE_ADMIN = 'GET_SALE_ADMIN'
export const POST_SALE_ADMIN = 'POST_SALE_ADMIN'
export const PUT_SALE_ADMIN = 'PUT_SALE_ADMIN'
export const DELETE_SALE_ADMIN = 'DELETE_SALE_ADMIN'

export const GET_COMPANY_POSITION = 'GET_COMPANY_POSITION'
export const POST_COMPANY_POSITION = 'POST_COMPANY_POSITION'
export const PUT_COMPANY_POSITION = 'PUT_COMPANY_POSITION'
export const DELETE_COMPANY_POSITION = 'DELETE_COMPANY_POSITION'

// Prices
export const GET_APP_PRICE = 'GET_APP_PRICE'
export const GET_APP_PRICE_BY_ID = 'GET_APP_PRICE_BY_ID'
export const POST_APP_PRICE = 'POST_APP_PRICE'
export const PUT_APP_PRICE = 'PUT_APP_PRICE'
export const DELETE_APP_PRICE = 'DELETE_APP_PRICE'

export const GET_CUSTOMER_PRICE = 'GET_CUSTOMER_PRICE'
export const GET_CUSTOMER_PRICE_BY_ID = 'GET_CUSTOMER_PRICE_BY_ID'
export const POST_CUSTOMER_PRICE = 'POST_CUSTOMER_PRICE'
export const PUT_CUSTOMER_PRICE = 'PUT_CUSTOMER_PRICE'
export const DELETE_CUSTOMER_PRICE = 'DELETE_CUSTOMER_PRICE'

export const GET_PRICE = 'GET_PRICE'
export const GET_PRICE_BY_ID = 'GET_PRICE_BY_ID'
export const POST_PRICE = 'POST_PRICE'
export const PUT_PRICE = 'PUT_PRICE'
export const DELETE_PRICE = 'DELETE_PRICE'
export const IMPORT_PRICE = 'IMPORT_PRICE'