import {
  GET_APP_PRICE,
  GET_APP_PRICE_BY_ID,
  POST_APP_PRICE,
  PUT_APP_PRICE,
  DELETE_APP_PRICE,
  GET_CUSTOMER_PRICE,
  GET_CUSTOMER_PRICE_BY_ID,
  POST_CUSTOMER_PRICE,
  PUT_CUSTOMER_PRICE,
  DELETE_CUSTOMER_PRICE,
  GET_PRICE,
  GET_PRICE_BY_ID,
  POST_PRICE,
  PUT_PRICE,
  DELETE_PRICE,
  IMPORT_PRICE
} from '../type'

const initialState = {
  listAppPrice: [],
  appPriceByid: [],
  listCustomerPrice: [],
  customerPriceByid: [],
  listPrices: [],
  priceById: []
}

const prices = ( state = initialState, action ) => {
  switch (action.type) {
    case GET_APP_PRICE:
      return {
        ...state,
        listAppPrice: action.data
      }
    case GET_APP_PRICE_BY_ID:
      return {
        ...state,
        appPriceByid: action.data
      }
    case POST_APP_PRICE:
      return { ...state }
    case PUT_APP_PRICE:
      return { ...state }
    case DELETE_APP_PRICE:
      return { ...state }
    case GET_CUSTOMER_PRICE:
      return {
        ...state,
        listCustomerPrice: action.data
      }
    case GET_CUSTOMER_PRICE_BY_ID:
      return {
        ...state,
        customerPriceByid: action.data
      }
    case POST_CUSTOMER_PRICE:
      return { ...state }
    case PUT_CUSTOMER_PRICE:
      return { ...state }
    case DELETE_CUSTOMER_PRICE:
      return { ...state }
    case GET_PRICE:
      return {
        ...state,
        listPrices: action.data
      }
    case GET_PRICE_BY_ID:
      return {
        ...state,
        priceById: action.data
      }
    case POST_PRICE:
      return { ...state }
    case PUT_PRICE:
      return { ...state }
    case DELETE_PRICE:
      return { ...state }
    case IMPORT_PRICE:
      return { ...state }
    default: {
      return state
    }
  }
}

export default prices