import {
  DASHBOARD_TECH,
  ORDER_TECH,
  DASHBOARD_SAAS,
  ORDER_SAAS
} from '../type'

const initialState = {
  dashboardTech: [],
  ordersTech: [],
  dashboardSaas: [],
  ordersSaas: []
}

const website = ( state = initialState, action ) => {
  switch (action.type) {
    case DASHBOARD_TECH:
      return {
        ...state,
        dashboardTech: action.data
      }
    case ORDER_TECH:
      return {
        ...state,
        ordersTech: action.data
      }
    case DASHBOARD_SAAS:
      return {
        ...state,
        dashboardSaas: action.data
      }
    case ORDER_SAAS:
      return {
        ...state,
        ordersSaas: action.data
      }
    default: {
      return state
    }
  }
}

export default website