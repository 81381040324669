import {
  GET_ACCOUNT_APP,
  POST_ACCOUNT_APP,
  PUT_ACCOUNT_APP,
  DELETE_ACCOUNT_APP,
  GET_ACCOUNT_COMPANY,
  POST_ACCOUNT_COMPANY,
  PUT_ACCOUNT_COMPANY,
  DELETE_ACCOUNT_COMPANY,
  GET_COMPANY_USER,
  POST_COMPANY_USER,
  PUT_COMPANY_USER,
  DELETE_COMPANY_USER,
  GET_SALE_ADMIN,
  POST_SALE_ADMIN,
  PUT_SALE_ADMIN,
  DELETE_SALE_ADMIN,
  GET_COMPANY_POSITION,
  POST_COMPANY_POSITION,
  PUT_COMPANY_POSITION,
  DELETE_COMPANY_POSITION
} from '../type'

const initialState = {
  listAccountApp: [],
  listAccountCompany: [],
  listCompanyUser: [],
  listSaleAdmin: [],
  listCompanyPosition: []
}

const account = ( state = initialState, action ) => {
  switch (action.type) {
    case GET_ACCOUNT_APP:
      return {
        ...state,
        listAccountApp: action.data
      }
    case POST_ACCOUNT_APP:
      return { ...state }
    case PUT_ACCOUNT_APP:
      return { ...state } 
    case DELETE_ACCOUNT_APP:
      return { ...state }
    case GET_ACCOUNT_COMPANY:
      return {
        ...state,
        listAccountCompany: action.data
      }
    case POST_ACCOUNT_COMPANY:
      return { ...state }
    case PUT_ACCOUNT_COMPANY:
      return { ...state }
    case DELETE_ACCOUNT_COMPANY:
      return { ...state }
    case GET_COMPANY_USER:
      return {
        ...state,
        listCompanyUser: action.data
      }
    case POST_COMPANY_USER:
      return { ...state }
    case PUT_COMPANY_USER:
      return { ...state }
    case DELETE_COMPANY_USER:
      return { ...state }
    case GET_SALE_ADMIN:
      return {
        ...state,
        listSaleAdmin: action.data
      }
    case POST_SALE_ADMIN:
      return { ...state }
    case PUT_SALE_ADMIN:
      return { ...state }
    case DELETE_SALE_ADMIN:
      return { ...state }
    case GET_COMPANY_POSITION:
      return {
        ...state,
        listCompanyPosition: action.data
      }
    case POST_COMPANY_POSITION:
      return { ...state }
    case PUT_COMPANY_POSITION:
      return { ...state }
    case DELETE_COMPANY_POSITION:
      return { ...state }
    default: {
      return state
    }
  }
}

export default account