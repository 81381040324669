import { useState, useEffect } from "react"

const useToken = () => {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    const tokenJson  = JSON.parse(tokenString)
    const now = new Date()
    if(tokenJson) {
      if(now.getTime() > tokenJson.expiry) {
        sessionStorage.removeItem('token')
      }
      return tokenJson.userToken
    }
    return null
  }

  const [token, setToken] = useState(getToken())
  useEffect(() => {
    const token = getToken()
    setToken(token)
  }, [])

  const saveToken = userToken => {
    const now = new Date()
    const ttl = 12 * 3600 * 1000
    const dataToken = {
      userToken: userToken,
      expiry: now.getTime() + ttl
    }
    sessionStorage.setItem('token', JSON.stringify(dataToken));
    setToken(dataToken.userToken)
    setTimeout(() => {
      window.location.href = `/dashboard`
    }, 200);
  }

  return {
    setToken: saveToken,
    token
  }
}

export default useToken