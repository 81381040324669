import {
  GET_PRODUCT_BRAND,
  POST_PRODUCT_BRAND,
  PUT_PRODUCT_BRAND,
  DELETE_PRODUCT_BRAND,
  GET_PRODUCT_CATEGORIES,
  POST_PRODUCT_CATEGORIES,
  PUT_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORIES,
  GET_PRODUCT_ATTRIBUTES,
  POST_PRODUCT_ATTRIBUTES,
  PUT_PRODUCT_ATTRIBUTES,
  DELETE_PRODUCT_ATTRIBUTES,
  GET_ATTRIBUTES_VALUE,
  POST_ATTRIBUTES_VALUE,
  PUT_ATTRIBUTES_VALUE,
  DELETE_ATTRIBUTES_VALUE,
  GET_PRODUCT,
  POST_PRODUCT,
  PUT_PRODUCT,
  DELETE_PRODUCT,
  IMPORT_PRODUCT,
  IMPORT_CATEGORIES,
  IMPORT_BRAND,
  IMPORT_ACTUAL_PHOTOS,
  EXPORT_ACTUAL_PHOTOS
} from '../type'

const initialState = {
  productBrand: [],
  productCategories: [],
  productAttributes: [],
  attributesValue: [],
  productsData: {},
  exportActualPhotos: []
}

const products = ( state = initialState, action ) => {
  switch (action.type) {
    case GET_PRODUCT_BRAND:
      return {
        ...state,
        productBrand: action.data
      }
    case POST_PRODUCT_BRAND:
      return {
        ...state
      } 
    case PUT_PRODUCT_BRAND:
      return {
        ...state
      }
    case DELETE_PRODUCT_BRAND:
      return {
        ...state
      }
    case GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategories: action.data
      }
    case POST_PRODUCT_CATEGORIES:
      return {
        ...state
      }
    case PUT_PRODUCT_CATEGORIES:
      return {
        ...state
      }
    case DELETE_PRODUCT_CATEGORIES:
      return {
        ...state
      }
    case GET_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        productAttributes: action.data
      }
    case POST_PRODUCT_ATTRIBUTES:
      return {
        ...state
      }
    case PUT_PRODUCT_ATTRIBUTES:
      return {
        ...state
      }
    case DELETE_PRODUCT_ATTRIBUTES:
      return {
        ...state
      }
    case GET_ATTRIBUTES_VALUE:
      return {
        ...state,
        attributesValue: action.data
      }
    case POST_ATTRIBUTES_VALUE:
      return {
        ...state
      }
    case PUT_ATTRIBUTES_VALUE:
      return {
        ...state
      }
    case DELETE_ATTRIBUTES_VALUE:
      return {
        ...state
      }
    case GET_PRODUCT:
      return {
        ...state,
        productsData: action.data
      }
    case POST_PRODUCT:
      return {
        ...state
      }
    case PUT_PRODUCT:
      return {
        ...state
      }
    case DELETE_PRODUCT:
      return {
        ...state
      }
    case IMPORT_PRODUCT:
      return {
        ...state
      }
    case IMPORT_CATEGORIES:
      return {
        ...state
      }
    case IMPORT_BRAND:
      return {
        ...state
      }
    case IMPORT_ACTUAL_PHOTOS:
      return {
        ...state
      }
    case EXPORT_ACTUAL_PHOTOS:
      return {
        ...state,
        exportActualPhotos: action.data
      }
    default: {
      return state
    }
  }
}

export default products