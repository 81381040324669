import {
  GET_ACCOUNT,
  POST_ACCOUNT,
  PUT_ACCOUNT,
  DELETE_ACCOUNT,
  GET_ROLES
} from '../type'

const initialState = {
  account: [],
  listRoles: []
}

const system = ( state = initialState, action ) => {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.data
      }
    case POST_ACCOUNT:
      return {
        ...state,
      }
    case PUT_ACCOUNT:
      return {
        ...state
      }
    case DELETE_ACCOUNT:
      return {
        ...state
      }
    case GET_ROLES:
      return {
        ...state,
        listRoles: action.data
      }
    default: {
      return state
    }
  }
}

export default system