import { INFO_USER, DATA_SIDE_BAR } from '../type'

const initialState = {
  infoUser: null,
  sideBar: []
}

const auth = ( state = initialState, action ) => {
  switch (action.type) {
    case INFO_USER:
      return {
        ...state,
        infoUser: action.data
      }
    case DATA_SIDE_BAR:
      return {
        ...state,
        sideBar: action.data
      }
    default: {
      return state
    }
  }
}

export default auth