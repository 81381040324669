import { combineReducers, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

import sideBar from './sideBar/reducer'
import auth from './auth/reducer'
import website from './website/reducer'
import products from './product/reducer'
import prices from './prices/reducer'
import system from './system/reducer'
import account from './account/reducer'

const rootReducer = combineReducers({
  sideBar, auth, website, products, system, account, prices
});

const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;